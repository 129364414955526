.Tictactoe-wrapper {
  overflow: hidden;
  box-sizing: border-box;
  width: 100%;
  height: calc(100vh - 90px);
  background: #fff;  
  display: flex;
  justify-content: center;
  align-items: center;
}
.master {
  overflow: hidden;
  box-sizing: border-box;
  width: 100%;
  height: calc(100vh - 90px);
  background: #fff;  
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.side-bar {
  justify-content: center;
  display: flex;
  align-items: center;
}
.tic-game {
  justify-content: center;
  display: flex;
  align-items: center;
}
.tic-board {
  display: flex;
  width: 450px;
  height: 450px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.tic-square {
  width: 150px;
  height: 150px;
  box-sizing: border-box;
  border: 1px dotted black;
  font-size: 4.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tic-square:hover {
  cursor: pointer;
  background-color: #f2f2f2;
}
.reset-button {
  padding: 10px 20px;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
  color: #000;
  cursor: pointer;
  background: #f2f2f2;
}
.reset-button:hover {
  background-color: #3f51b5;
  color: #fff;
}
.active-player-button {
  padding: 10px 20px;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: #3f51b5;
  cursor: pointer;
  margin-left: 10px;
}
.deactived-player-button {
  padding: 10px 20px;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
  color: #000;
  cursor: pointer;
  background: #f2f2f2;
  margin-left: 10px;
}
.active-player-button:hover {
  background: #3f51b5;
  color: #fff;
}
.deactived-player-button:hover {
  background: #3f51b5;
  color: #fff;
}
.result-box {
  display: flex;
  flex-direction: column;
}
.result {
  display: flex;
  font-size: 1rem;
}
.winner {
  display: flex;
  font-size: 2rem;
  height: 45px;
  width: 200px;
}
@media (max-width: 1100px) {
  .Tictactoe-wrapper {
    flex-direction: column;
  }
  .master {
    justify-content: center;
  }
  .tic-board {
    width: 600px;
    height: 600px;
  }
  .tic-square {
    width: 200px;
    height: 200px;
    font-size: 4rem;
  }
  .tic-game {
    margin: 1rem 0 3rem 0;
  }
}
@media (max-width: 800px) {
  .tic-board {
    width: 450px;
    height: 450px;
  }
  .tic-square {
    width: 150px;
    height: 150px;
    font-size: 3.5rem;
  }
  .tic-game {
    margin: 1rem 0 3rem 0;
  }
}

@media (max-width: 640px) {
  .tic-board {
    width: 300px;
    height: 300px;
  }
  .tic-square {
    width: 100px;
    height: 100px;
    font-size: 2rem;
  }
  .reset-button {
    padding: 10px;
  }
  .active-player-button {
    padding: 10px;
  }
  .deactived-player-button {
    padding: 10px;
  }
  .tic-game {
    margin: 1rem 0;
  }
  .winner {
    font-size: 1.5rem;
    justify-content: center;
}
}
