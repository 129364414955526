h1,
p {
  margin: 10px;
}
input {
  margin-left: 10px;
}
.Puzzle {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: calc(100vh - 90px);
}
.board-box {
  min-width: 300px;
  margin: 3rem 10px;
}
.square {
  display: inline-block;
  box-sizing: border-box;
  width: 60px;
  height: 60px;
  background: #ddd;
  border: 1px solid #f2f2f2;
  line-height: 60px;
  text-align: center;
  font-size: 20px;
}
.zero {
  background: beige;
}
.Puzzle-info {
  font-size: 1rem;
  text-align: center;
}
.Puzzle-buttons input {
  border: none;
  outline: none;
  background: #ddd;
  font-size: 14px;
  padding: 7px 10px;
  cursor: pointer;
}
.Puzzle-buttons input:hover {
  border: none;
  outline: none;
  background: #3f51b5;
  color: #fff;
}
.Squares-info {
  width: 58%;
  margin: 0 auto;
}
@media (max-width: 1100px) {
  .Puzzle {
    flex-direction: column;
    height: calc(100vh - 90px);
    justify-content: space-between;
  }
  .square {
    width: 100px;
    height: 100px;
    line-height: 100px;
  }
  .Puzzle-buttons {
    display: flex;
    justify-content: space-between;
  }
}
@media (max-width: 640px) {
  .square {
    width: 60px;
    height: 60px;
    line-height: 60px;
  }
  .Puzzle-buttons input {
    padding: 6px;
  }
}
