.ConnectCards {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.landing {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
}

.landing > h1 {
  margin: 0;
  font-weight: 500;
  font-size: 4rem;
  letter-spacing: 2px;
}

.landing > p {
  margin: 0;
  text-align: center;
  letter-spacing: 2px;
}

.landing > button {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
  background: none;
  border: 5px solid #3f51b5;
  letter-spacing: 4px;
  font-size: 2rem;
  font-weight: 500;
  padding: 0 1rem;
  cursor: pointer;
  animation: buttonAnimation 0.7s ease-in-out infinite forwards;
  max-width: 230px;
}

@media screen and (max-width: 800px) {
  .landing > h1 {
    font-size: 6rem;
  }
}

@media screen and (max-width: 576px) {
  .landing > h1 {
    font-size: 4.5rem;
  }

  .landing > p {
    letter-spacing: 1px;
  }
}

@media screen and (max-width: 420px) {
  .landing > h1 {
    font-size: 3.2rem;
  }
  .landing > button {
    font-size: 1.5rem;
  }
}

@keyframes buttonAnimation {
  from {
    background-color: #3f51b5;
    color: #fff;
  }
  to {
    background-color: #fff;
    color: #000;
  }
}

/* Layout */

.very-specific-design {
  width: 50vw;
  height: 85vh;
  /*  padding: 50px;*/
  text-align: center;
  background: white;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transform-origin: center center;
  display: flex;
  align-items: center;
}

.scaleable-wrapper {
  width: 50vw;
  resize: both;
  position: relative;
  height: 85vh;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
  width: 50vw;
  justify-content: space-between;
  height: 70vh;
  margin: 0 auto;
}

.flex-container > div {
  background-color: #c9d1cb;
  width: 20%;
  height: 20%;
  margin: 5px;
  border-radius: 3.5px;
  text-align: center;
  line-height: 75px;
  font-size: 30px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  transition: color 0.15s ease-in-out, background-color 0.8s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
}

.flex-container > div:hover {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}

@media screen and (max-width: 1650px) {
  .flex-container {
    height: 60vh;
  }
}
@media screen and (max-width: 1100px) {
  .ConnectCards {
    flex-direction: column;
  }
  .flex-container {
    height: 50vh;
  }
  .scaleable-wrapper {
    height: 65vh;
  }
  .very-specific-design {
    height: 65vh;
  }
}

@media screen and (min-width: 576px) {
  .flex-container {
    width: 360px;
  }
}
@media screen and (max-width: 576px) {
  .flex-container {
    width: 340px;
  }
  .landing {
    height: 60vh;
  }
  .very-specific-design,
  .scaleable-wrapper {
    width: 80vw;
    height: 60vh;
  }
}
