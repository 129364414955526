.clear {
  clear: both;
  content: "";
}
.MineGame {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: calc(100vh - 90px);
}
.mgame {
  max-width: 480px;
  width: 480px;
  margin: 0 auto;
  padding: 20px;
  background: #f2f2f2;
}
.mgame .mgame-info {
  margin-bottom: 20px;
  background: transparent;
  padding: 7px;
  text-align: center;
  color: #333;
  min-height: 100px;
  border-radius: 7px;
}
.mgame .mgame-info .info {
  display: block;
  margin-top: 15px;
}
.mgame .mgame-info h1,
.mgame .mgame-info p {
  margin: 0;
}
.mgame .cell {
  background: #fcfaf9;
  border: 1px solid #ddd;
  border-radius: 4px;
  float: left;
  line-height: 55px;
  height: 55px;
  text-align: center;
  width: 55px;
  cursor: pointer;
  color: #333333;
  font-weight: 600;
}
.mgame .cell:focus {
  outline: none;
}
.mgame .hidden {
  background: #ccc !important;
}  
.mgame .is-flag,
.mgame .is-mine {
  color: #fc543c;
}
.reset-mine {
  margin-top: 5px;
  padding: 5px 20px;
  cursor: pointer;
  color: #333;
  text-decoration: none;
}
@media (max-width: 1300px) {
  .mgame {
    max-width: 440px;
    width: 440px;
  }
  .mgame .cell {
    background: #fcfaf9;
    border: 1px solid #ddd;
    border-radius: 4px;
    float: left;
    line-height: 50px;
    height: 50px;
    text-align: center;
    width: 50px;
    cursor: pointer;
    color: #333333;
    font-weight: 600;
  }
}

@media (max-width: 1100px) {
  .MineGame {
    flex-direction: column;
    height: calc(100vh - 120px);
  }
}

@media (max-width: 600px) {
  .MineGame {
    flex-direction: column;
    height: calc(100vh - 100px);
  }
  .mgame {
    max-width: 320px;
    width: 320px;
    padding: 8px;
  }
  .mgame .cell {
    background: #fcfaf9;
    border: 1px solid #ddd;
    border-radius: 4px;
    float: left;
    line-height: 38px;
    height: 38px;
    text-align: center;
    width: 38px;
    cursor: pointer;
    color: #333333;
    font-weight: 600;
  }
  .mgame .mgame-info {
    margin-bottom: 10px;
  }
  h1.info {
    font-size: 20px;
    margin-top: 5px;
  }
  span.info {
    font-size: 16px;
    margin-top: 5px;
  }
}