body {
  background: #fff !important;
  font-family: Roboto;
  font-size: 14px;
}
h1,
h2,
h3,
h4,
h5,
h6,
a {
  font-family: Roboto slab;
}
.App {
  text-align: center;
  background: #fff;
}

.toolbarBox {
  display: flex;
  align-items: center;
  justify-content: center;
}
.toolbarBox > div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.toolbarLogo {
  margin: 0;
}
.toolbarLogo a:nth-child(1) {
  margin-right: 10px;
}
.toolbarLogo a svg {
  font-size: 2rem;
}
.navbar {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin: 0;
  padding: 0;
  list-style-type: none;
  align-items: center;
}
.navbar li a {
  color: #fff;
  text-decoration: none;
  padding: 0 10px;
}
.all-games {
  background: #f2f2f2;
  padding: 10px 20px;
  color: #333;
}
.side-app {
  position: absolute;
  width: 100%;
  bottom: 20px;
}
.side-app .Download-android,
.side-app .Download-ios {
  width: 45%;
}
.tetrisControls {
  position: relative;
  width: 150px;
  height: 150px;
}
.tetrisControls button {
  background-color: #f2f2f2;
  border: 1px solid #ebebeb;
  border-radius: 3px;
  display: inline-block;
  color: #333;
  font-size: 25px;
  padding: 0;
  text-decoration: none;
  margin: 5px;
  transition: all 0.5s;
  max-width: 40px;
  min-width: 40px;
  height: 40px;
}
.goTop {
  transform: rotate(-90deg);
  position: absolute;
  top: 0;
  left: calc(50% - 20px);
}
.goLeft {
  transform: rotate(180deg);
  position: absolute;
  top: calc(50% - 20px);
  left: 0;
}
.goRight {
  position: absolute;
  top: calc(50% - 20px);
  left: calc(100% - 40px);
}
.goDown {
  transform: rotate(90deg);
  position: absolute;
  bottom: 0;
  left: calc(50% - 20px);
}
.Tetris-Game {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ReklamaVetikal {
  display: block;
}
.ReklamaHorizontal {
  display: none;
  margin-bottom: 2rem;
}
.ReklamaHorizontalMob {
  display: none;
}
.tetrisControlsMob {
  display: none;
}
.HomeGame {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  height: calc(100vh - 90px);
}
.HomeGame > div {
  display: flex;
  width: 100%;
}
.HomegameCard {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  display: flex;
  flex: 1;
  justify-content: center;
}
.HomegameCard img {
  width: 200px;
  height: 200px;
}
.HomegameCard.big-img img {
  height: 300px;
  width: auto;
}
.HomegameCard a {
  text-decoration: none;
  color: #333;
  font-size: 20px;
}
.MuiSelect-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
.MuiSelect-select.MuiSelect-select {
  padding-right: 0px;
}
.makeStyles-formControl-105 {
  min-width: 110px !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.MuiInput-underline:before {
  border-bottom: none !important;
}
.MuiInputBase-input {
  padding: 0 5px 2px 0 !important;
}
.HeaderTitleLink {
  color: #fff;
  text-decoration: none;
}
.Menu-icon {
  width: 30px;
  height: 30px;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
}
.Mobile-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 700px;
}
.swipe {
  position: absolute;
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.3);
  max-width: 230px;
  height: 500px;
  border-radius: 10px;
  cursor: pointer;
}
.cardContainer {
  width: 90vw;
  max-width: 230px;
  height: 500px;
}
.card {
  position: relative;
  background-color: #fff;
  width: 80vw;
  max-width: 230px;
  height: 500px;
  border-radius: 10px;
  background-size: cover;
  background-position: center;
}
.card h3 {
  position: absolute;
  bottom: 0;
  margin: 10px;
  color: #fff;
}
.swipe:nth-child(2) .card {
  transform: rotate(4deg);
}
.swipe:nth-child(3) .card {
  transform: rotate(2deg);
}
.swipe:nth-child(4) .card {
  transform: rotate(9deg);
}
.swipe:nth-child(5) .card {
  transform: rotate(6deg);
}
.swipe:nth-child(6) .card {
  transform: rotate(3deg);
}

.Download-ios img {
  width: 30px;
}
.Download-android img {
  width: 30px;
}
.Donwload-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 0 18px;
  margin-bottom: 10px;
}
.Download-android,
.Download-ios {
  display: flex;
  width: 165px;
  height: 60px;
  justify-content: space-evenly;
  background: #3f51b5;
  align-items: center;
  color: #fff;
  color: #fff;
  text-decoration: none;
}
.Download-android:hover,
.Download-ios:hover {
  box-shadow: 1px 0px 2px rgba(0, 0, 0, 0.3);
}
.Download-title {
  display: flex;
  flex-direction: column;
}
.qr-code {
  width: 420px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.qr-code-img {
  width: 200px;
  height: 200px;
}
.Apps-title {
  font-size: 1.5rem;
}
.Mobile-box {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  flex-direction: row-reverse;
}
.ok-img {
  width: 20px;
  height: 20px;
  margin-top: 5px;
}
@media (max-width: 1400px) {
  .HomegameCard img {
    width: 180px;
    height: 180px;
  }
}
@media (max-width: 1100px) {
  .Tetris-Game {
    flex-direction: column;
  }
  .ReklamaVetikal {
    display: none;
  }
  .ReklamaHorizontal {
    display: block;
  }
  .tetrisControls button {
    max-width: 60px;
    min-width: 60px;
    height: 60px;
  }
  .tetrisControls {
    position: relative;
    width: 240px;
    height: 213px;
  }
  .goTop {
    left: calc(50% - 30px);
  }
  .goLeft {
    top: calc(50% - 30px);
  }
  .goRight {
    top: calc(50% - 30px);
    left: calc(100% - 60px);
  }
  .goDown {
    left: calc(50% - 30px);
  }
  .HomeGame > div {
    flex-wrap: wrap;
  }
  .HomeGame > div:nth-child(2) {
    margin-bottom: 30px;
  }
  .HomeGame > div:nth-child(1) .HomegameCard {
    flex-basis: 50%;
    margin-bottom: 30px;
  }
  .HomeGame > div:nth-child(2) .HomegameCard {
    flex-basis: 50%;
  }
  .HomegameCard.big-img img {
    height: 250px;
    width: auto;
  }
  .Mobile-container {
    justify-content: space-around;
    height: 800px;
  }
  .all-games {
    padding: 1px 20px;
  }
}
@media (max-width: 640px) {
  h1 {
    font-size: 20px;
  }
  .ReklamaHorizontal {
    display: none;
  }
  .tetrisControls {
    display: none !important;
  }
  .tetrisControlsMob {
    position: fixed;
    bottom: 55px;
    display: flex;
    justify-content: space-around;
    width: 100%;
  }
  .tetrisControlsMob button {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    background-color: #f2f2f2;
    border: 1px solid #ebebeb;
    border-radius: 3px;
    display: inline-block;
    color: #333;
    font-size: 25px;
    padding: 0;
    text-decoration: none;
    margin: 5px;
    transition: all 0.5s;
    max-width: 40px;
    min-width: 40px;
    height: 40px;
  }
  .ReklamaHorizontalMob {
    display: block;
    margin-bottom: 1rem;
  }
  .Headertitle {
    display: none;
  }
  .HomeGame {
    flex-direction: column;
    flex-wrap: nowrap;
    height: auto;
  }
  .HomeGame > div:nth-child(2) .HomegameCard {
    flex-basis: 90%;
    margin-bottom: 30px;
  }
  .Mobile-box {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: column-reverse;
    align-items: center;
    margin: 40px 0;
  }
  .qr-code {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 20px;
    margin-top: 30px;
    align-items: center;
  }
  .qr-code-img {
    width: 150px;
    height: 150px;
  }
  .Download-android,
  .Download-ios {
    display: flex;
    width: 135px;
    height: 60px;
    justify-content: space-evenly;
    background: #3f51b5;
    align-items: center;
    color: #fff;
    color: #fff;
    text-decoration: none;
  }
  .Donwload-wrapper {
    padding: 0;
  }
  .side-app .Donwload-wrapper {
    padding: 10px 0;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: 150px;
  }
  .Mobile-container {
    height: auto;
  }
  .side-app {
    position: relative;
    width: 100%;
    bottom: 20px;
    margin-top: 20px;
  }
}
