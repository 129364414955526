.Game {
  margin: 2px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: calc(100vh - 90px);
}
.Game > div {
  display: flex;
  flex-direction: column;
}
button {
  background-color: #5689db;
  border: 1px solid #5689db;
  border-radius: 3px;
  display: inline-block;
  color: #fff;
  font-size: 12pt;
  padding: 7px 28px;
  text-decoration: none;
  margin: 5px;
  transition: all 0.5s;
  max-width: 160px;
  min-width: 160px;
}
button:hover {
  background-color: lightblue;
  font-size: 14pt;
}
button:focus {
  outline: none;
}
.Board {
  display: flex;
  background: #5689db;
}
.Slat {
}
.Hole {
  padding: 5px;
  margin: 4px;
  min-width: 50px;
  min-height: 50px;
  background: #fff;
  border-radius: 50px;
}
.Hole div {
  position: relative;
  min-width: 50px;
  min-height: 50px;
  border-radius: 50px;
  top: -400px;
  transition: transform 0.5s; 
}
.Red {
  background-color: #f04545;
  transform: translateY(400px);
}
.Blue {
  background-color: rgb(232 232 79);
  transform: translateY(400px);
}
.winnerMessage {
  margin: 20px;
  font-size: 0pt;
  font: 12pt;
  opacity: 0;
  transition: all 0.5s cubic-bezier(0.18, 0.99, 0.8, 1.17);
}
.appear {
  opacity: 1;
  font-size: 20pt;
}
.conect4-button {
  font-size: 16px;
}
.conect4-button:hover {
  font-size: 16px;
  background: #3f51b5;
}
@media (max-width: 1100px) {
  .Connect4-wrapper {
    height: calc(100vh - 90px);
  }
  .Game {
    justify-content: center;
    flex-direction: column;
  }
  .Board {
    margin-top: 3rem;
  }
  .Hole {
    min-width: 70px;
    min-height: 70px;
  }
  .Hole div {
    min-width: 70px;
    min-height: 70px;
  }
}
@media (max-width: 640px) {
  .Board {
    margin: 0 auto;
  }
  .Hole {
    min-width: 30px;
    min-height: 30px;
  }
  .Hole div {
    min-width: 30px;
    min-height: 30px;
  }
}
