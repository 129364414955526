.Mastermind-wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: calc(100vh - 90px);
}
.game-container {
  display: flex;
  width: 650px;
}
.game-container > div {
  flex: 1;
}
.mastermind-rules {
  margin: 0.33em auto 0.8em auto;  
  max-width: 450px;
}

.mastermind-rules h3 {
  font-size: 1.05em;
  margin: 5px 0 0 0;
  color: #333;
  font-weight: normal;
  cursor: pointer;
}

.mastermind-rules p {
  text-align: justify;
  -moz-text-align-last: center;
  text-align-last: center;
  margin: 5px 1em;
  font-size: 0.92em;
  color: #666;
}

.colors .color-holder,
.mastermind-board .peg {
  display: inline-block;
  width: 25px;
  height: 25px;
  border: 1px solid #777;
  margin-right: 8px;
  border-radius: 50%;
  cursor: pointer;
  background-color: #f0eaed;
}

.colors .red,
.circles .red {
  background-color: #e42244;
}

.colors .green,
.circles .green {
  background-color: #70c030;
}

.colors .orange,
.circles .orange {
  background-color: #ffbb36;
}

.colors .blue,
.circles .blue {
  background-color: #36bedd;
}

.colors .purple,
.circles .purple {
  background-color: #9f66df;
}

.colors .active {
  border: 2px solid black;
  border-radius: 50%;
}

.mastermind-board {
  margin-top: 15px;
}

.mastermind-board div {
  display: inline-block;
  margin-right: 5px;
  vertical-align: bottom;
}

.mastermind-board .circles {
  margin-left: 5px;
  display: flex;
}

.mastermind-board .ok-button {
  font-size: 0.9em;
  color: #33aa88;
  margin-right: 12px;
  cursor: pointer;
}

.mastermind-board .ok-button.disabled {
  color: #ccc0c6;
  cursor: default;
}

.mastermind-board .ok-button.hidden {
  display: none;
  cursor: default;
}

.mastermind-board .row {
  display: block;
  border: 1px solid #d0cacd;
  margin: 0 auto 3px auto;
  max-width: 360px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 5px;
}

.mastermind-board .row.active {
  border: 2px solid #bbaaaa;
  background-color: #fff9fc;
}

.mastermind-board .hints {
  margin-right: 0;
  display: flex;
  flex-wrap: wrap;
  width: 40px;
  justify-content: center;
  align-items: center;
}

.mastermind-board .hints span {
  display: inline-block;
  width: 15px;
  height: 15px;
  border: 1px solid #777;
  background-color: #f0eaed;
  font-size: 0.75em;
  margin: 2px;
  border-radius: 50%;
}

.mastermind-board .hints span.exact {
  background-color: green;
}

.mastermind-board .hints span.partial {
  background-color: red;
}

.solution p {
  margin-top: 10px;
  margin-bottom: 5px;
}

.solution .hidden {
  display: none;
}

.solution .color-holder {
  cursor: default;
}

.solution a {
  color: #338855;
  font-size: 1.1em;
  text-decoration: underline;
  cursor: pointer;
}

.msg {
  margin: 0.5em auto;
}

@media (max-width: 1100px) {
  .Mastermind-wrapper {
    flex-direction: column;
    height: calc(100vh - 90px);
    justify-content: space-between;
  }
  .game-container {
    display: flex;
    width: 750px;
  }
  .colors .color-holder,
  .mastermind-board .peg {
    width: 30px;
    height: 30px;
  }
  .mastermind-board .row {
    max-width: 390px;
    padding: 15px;
  }
  .mastermind-board .hints {
    width: 50px;
  }
  .mastermind-board .hints span {
    width: 20px;
    height: 20px;
  }
  .mastermind-board .ok-button {
    font-size: 1.2rem;
  }
}
@media (max-width: 840px) {
  .mastermind-board .row {
    max-width: 360px;
    padding: 10px;
  }
}
@media (max-width: 640px) {
  .game-container {
    display: flex;
    width: 300px;
    flex-direction: column;
  }
  .colors .color-holder,
  .mastermind-board .peg {
    width: 20px;
    height: 20px;
  }
  .mastermind-board .row {
    max-width: 300px;
    padding: 1px;
  }
  .mastermind-board .hints {
    width: 40px;
  }
  .mastermind-board .hints span {
    width: 15px;
    height: 15px;
    margin: 0px;
  }
  .mastermind-board .ok-button {
    font-size: 1rem;
  }
}
